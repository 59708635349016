/* Font */

@font-face {
  font-family: $font-1;
  src: url('../font/Lato-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: $font-2;
  src: url('../font/Lato-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: $font-3;
  src: url('../font/Lato-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}
