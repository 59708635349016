
* {outline:0;}

/* Title style */
.edito-t{
  h1, h2, h3{
    border-bottom: 1px solid $border-color-1;
    line-height: 30px;
    height: 17px;
    text-align: center;
    font-size: 1rem;
    margin: 2rem 0;
    span,a{
      background: #ffffff;
      font-size: 1.2rem;
      padding: 1rem;
      font-family: $font-2, serif;
      line-height: 30px;
      height: 30px;
      text-transform: uppercase;
    }
  }
  .sub-title{
    font-size: 1.15rem;
    line-height: 1.15rem;
    margin-bottom: 0;
    font-family: $font-2, serif;
  }
}

body{
  position: relative;
  font-family: $font-1, sans-serif;
}


.no-pr{
  padding-right: 0!important;
}

.no-pl{
  padding-left: 0!important;
}

.container-slider .orbit-previous, .container-slider .orbit-next{
  color: #000000;
  font-size: 1rem;
}

/* Header */

#main-header{


  margin-bottom: 30px;

  .container-logo{
    margin-top: 35px;
  }
  .is-dropdown-submenu-parent.opens-right>.is-dropdown-submenu{
    left: 0;
    top: 30px;
    background: rgba(255,255,255, 0.9);
    text-align: center;
    z-index: 1000000;
    a{
      font-size: .9rem;
    }
  }
  .main-menu{
    .title-bar{
      display: none;
    }
    margin-top: 1rem;
    .top-bar input{
      height: 40px;
    }
    li{
      display: inline-block;
      text-transform: uppercase;
      a{
        font-family: $font-1, sans-serif;
        color: #787878;
        font-size: 1.3rem;
        letter-spacing: -0.05rem;
        &:hover{
          font-family: $font-2, sans-serif;
          color: black;
        }
      }
    }
    .menu-item-social{
      position: absolute;
      right: 0;
      li{
        text-align: center;
        &:first-child{
          width: 310px;
          text-align: right;
          input{
            float: left;
            border: 0;
            border-bottom: 1px solid $border-color-1;
          }
          button{
            margin-right: 10px;
            font-family: $font-2, serif;
            font-weight: 600;
          }
          .autocomplete-suggestion{

            em{
              text-decoration: none;
            }
            font-family: $font-2, serif;
          }
        }
        a{
          padding-left: 0;
          font-size: .9rem;
        }
      }
    }
  }
  .menu-icon:after{
    background: #a9a9a9;
    box-shadow:0 7px 0 #a9a9a9, 0 14px 0 #a9a9a9;
  }
}

/* Footer */

#main-footer{
  margin: 3rem 0 1rem;
  .menu > li{
      display: inline-block;
      font-family: $font-1, sans-serif;
      font-size: 14px;
      a{
        padding: 0;
        font-size: 14px;
      }
    }
  .gototop{
    display:none;
    width: 55px;
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
}



/* Errors */

.form-errors{

}





/* Become model page */

.form-become-model{

}

.formular{
  margin-top: 30px;
  padding: 0 8%;
  .form{
    border-bottom: 1px solid $border-bottom-field;
    margin-top: 30px;
    height: 2.5rem;
    @include clearfix;
    & label{
      width: 30%;
      float: left;
      text-transform: uppercase;
      margin-top: 7px;
      cursor: pointer;
      font-family: $font-2, serif;
      font-size: 1rem;
    }

    &.number input, &.text input{
      width: 70%;
      float: left;
      margin-bottom: 0;
      border: 0;
    }
    &.textarea{
      height: 6rem;
      textarea{
        width: 70%;
        float: left;
        margin-bottom: 0;
        border: 0;
        height: 5.5rem;
      }
    }
    .choices-container{
      label{
        width: 50px;
        float: left;
        margin-bottom: 1rem;
      }
      input{
        width: 15px;
        float: left;
        margin-top: 13px;
        margin-bottom: 0;
      }
    }
    &.file{
      height: auto;
      border: 0;
      label{
        text-align: center;
        width: 100%;
        height: 35px;
        position: absolute;
        top: 0;
        width: 100%;
      }
      input{
        height: 430px;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
  .container-field-upload{
    position: relative;
    height: 430px;
    overflow: hidden;
    margin-bottom: 40px;
    img{
      position: absolute;
      top: 45px;
      width: calc(100% - #{$gutter} * 2);
    }
  }
  .container-robot-security{
    margin: 1rem 0;
  }
  .container-submit-become-form{
    input{
      background: #000;
      font-family: $font-3, serif;
      color: #fff;
      border: 0;
      padding: .7rem 1.4rem;
      text-transform: uppercase;
    }
  }
}

/* contact */

.container-contact-map{
  margin-top: 30px;
}

.container-adress{
  font-family: $font-2, serif;
  font-size: 1.1rem;
  padding: 2rem 10%;
  margin-bottom: 0;
  strong{
    font-family: $font-3, serif;
  }
}

.about-text{
  padding: 0 17%;
  font-family: $font-2, serif;
  line-height: 2rem;
}



/* talent page */

.talent-page{
  position: relative;
  .print-modal{
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
#print-modal{
  p{
    &.title-print-modal{
      text-align: center;
      font-size: 1.1rem;
    }
    text-align: center;
  }
}

.container-slider{
  margin-top: 30px;
  height: calc(100vh - 352px);

  .orbit{
    height: inherit!important;
    .orbit-container{
      height: inherit!important;
      .orbit-previous, .orbit-next{
        width: 50%;
        height: 100%;
      }
      .orbit-previous{
        text-align: left;
      }
      .orbit-next{
        text-align: right;
      }
      .orbit-slide{
        height: inherit!important;
        .container-img-slider{
          height: inherit!important;
          display: table;
          .container-talent-info-slide{
            display: table-cell;
            vertical-align: middle;
          }
          .content-img-slider{
            display: table-cell;
            vertical-align: middle;
            img{

            }
          }
        }
      }
    }
  }
}

.container-talent-info-slide{
  h1{
    font-family: $font-3, serif;
    font-size: 2rem;
    border: 0;
    margin-bottom: 2rem;
    height: 2rem;
  }
  li{
    margin-bottom: 2vh;
    font-family: $font-2, serif;
  }
}

.second-measurement-info{
  margin-top: 1rem!important;
  &.menu > li{
    display: inline-block;
    font-family: $font-2, serif;
    margin-right: 1rem;
    strong{
      font-family: $font-3, serif;
    }
  }
}


.arrow-icons {
  width: 40px;
}

.print-icon {
  width: 20px;
  padding-top:20px;
}



/* Terms & conditions */

.term-condition{
  h1{
    border: 0;
  }
}

.fifth{
  width: 20%;
  float: left;
  padding: 0px 10px;
}

.container-header{
  padding: 0 120px;

}



.top-bar{
  margin-top: 44px;
}

li.itemenu{
  padding: 10px 34px;
}

.reveal-overlay{
  background-color: rgba(225, 225, 225, 0.75) !important;
  .reveal{
    border: none;
    padding: 40px;
    color: #787878;
    .city-name{
      font-family: $font-3;
    }
  }
}


.googlemap-btn{
  text-transform: uppercase;
  border: 1px solid #787878;
  padding: 10px 40px;
  display: inline-block;
  margin-top: 20px;
  color: #787878;
  &:hover{
    color: #787878;
  }
}

.agency-description{
  padding-top: 30px;
  border-top: 1px solid #787878;
  margin-top: 30px;
}



.contact-box{
  width: 835px!important;
  font-family: $font-2;

}

.home{
  height: 84vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

.homepage{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  position: relative;
}

footer .menu-home {
  position: absolute;
  bottom: 10px;
  left:50%;
  transform: translateX(-50%);
}


.menu-home li{
  display: inline-block;
}

footer li{
  font-family: $font-1, sans-serif;
}

.container-logo img {
  width: 300px;
}

#main-header .menu.medium-horizontal li.active-link a{
  font-family: $font-3, sans-serif;
}

.top-bar, .top-bar ul{
  padding-right: 30px;
}

ul.social{
  display: inline-block;
  li{
    padding: 10px;
    position: relative;
    top:3px;
  }
}

@media screen and (max-width:1388px) {
  li.itemenu {
    padding: 10px 0px;
  }
}


@media screen and (max-width:1119px) {
  .container-logo{
    text-align: center;
  }
  .vertical.medium-horizontal.menu.text-right{
    text-align: center;
  }
}

@media screen and (max-width: 640px) {
  .container-header{
    padding: 0;
  }
}