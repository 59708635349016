
/* Board 1 */

.board-1{

  .container-talent-list-element{
    margin-top: 1.5rem;
    position: relative;
    width: 372px;
    img{
      display: none;
    }
    .content-img-talent-list{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      height: 440px;
      overflow: hidden;
    }
    &:hover{
      .content-img-talent-list{
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        filter: grayscale(0%);
      }
    }
    p{
      font-family: $font-2, serif;
      margin-top: .3rem;
    }
    &:hover{
      p{
        visibility: hidden;
      }
      .content-info-talent{
        display: block;
      }
    }
    .content-info-talent{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.4);
      display: none;
      ul.container-main-stat{
        margin: 0;
        height: 440px;
        padding-bottom: 60px;
        line-height: 36px;
        li{
          text-align: center;
          font-family: Helvetica, serif;
          &.container-talent-name{
            font-size: 1.75rem;
            font-weight: 100;
            margin-top: 70px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .container-social-media{
    ul{
      display: block;
      text-align: center;
      padding: 0 30px;
      margin: 0;
      li{
        display: inline;
        margin-left: 15px;
      }
    }
  }

  .board-all{
    padding: 0 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }



  /* talent list breakpoint */


}