
/* Specific Variable */

$border-color-1: #d4d4d4;
$border-bottom-field: #ababab;
$gutter: .9375rem;

$font-1: 'abel';
$font-2: 'playfair';
$font-3: 'playfair-bold';

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}