

/* responsive mobile */

@media screen and (max-width: 640px){

  /* header responsive */
  #main-header{
    .menu.medium-horizontal > li{
      display: block!important;
      text-transform: uppercase!important;
      text-align: left;
      width: 100%;
      margin-top: 0!important;
      &.separator{
        display: none!important;
      }
      &.main-menu-item{
        ul.is-dropdown-submenu{
          display: block!important;
          left: 0;
          top: 45px;
          margin:0;
          min-width: 500px;
          li{
            float: left;
            display: inline;
            margin-left: 1rem;
            padding-right: 1rem;
            width: auto;
            height: 10px;
            line-height: 10px;
            border-right: 1px solid $border-color-1;
            &:last-child{
              border: 0;
            }
            &.active{
              a{
                color: #999999;
              }
            }
            a{
              font-size: 1.1rem;
              color: #000;
              padding: 0;
              line-height: .7rem;
            }
          }
        }
      }
      a{
        font-family: $font-1, sans-serif;
        font-size: 1.25rem;
      }
      .submenu{
        position: relative!important;
        top: 0!important;
        min-width: auto!important;
        li{
          border: 0!important;
          width: 100%!important;
          line-height: 28px!important;
          height: 28px!important;
          a{
            line-height: 1rem!important;
          }
        }
      }
    }
    .menu{
      background: #fff;
      li{
        padding-left: $gutter;
        &.container-search-header{
          width: 100%!important;
          display: block;
          padding:0 $gutter;
          text-align: left!important;
          input{
            width: 75%!important;
          }
          button{
            width: 25%!important;
          }
        }
      }
    }
  }

  /* formular responsive */

  .formular{
    .form{
      height: auto;
      label{
        width: 100%;
      }
      &.number input, &.text input{
        width: 100%;
      }
      &.textarea{
        height: auto;
        textarea{
          width: 100%;
        }
      }
    }
  }

  /* Talent page */

  .container-list-mobile div ul li.orbit-slide .small-6 {
    width:100%;
  }
  .content-img-slider img {
    width:100%;
  }


  /* Footer */

  #main-footer{
    margin: 5rem auto 1rem;
    .menu > li{
      display: inline-block;
      font-family: $font-1, sans-serif;
      height: 15px;
      line-height: 15px;
      a{
        padding: 0;
      }
    }
  }

  /* Home page */


  .index-1 {
    .home-slide {
      height: 200px !important;
    }
    .container-feed-social{
      width: auto!important;
      .facebook-feed-container.columns,.instagram-feed-container.columns,.twitter-feed-container{
        width: 100%!important;
      }
    }
  }


  .tumblr-container {
    -moz-column-count: 2;
    -moz-column-gap: $gutter;
    -webkit-column-count: 2;
    -webkit-column-gap: $gutter;
    column-count: 2;
    column-gap: $gutter;
    width: 100%;
  }

  /* talent page */
  .second-measurement-info{
    display: none;
  }

}

