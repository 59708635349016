/* talent page */

.talent-page.talent-1{
  position: relative;
  .print-modal{
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
.talent-1{
  .container-slider{
    margin-top: 30px;
    height: calc(100vh - 322px);

    .orbit{
      height: inherit!important;
      .orbit-container{
        height: inherit!important;
        .orbit-previous, .orbit-next{
          width: 50%;
          height: 100%;
        }
        .orbit-previous{
          text-align: left;
        }
        .orbit-next{
          text-align: right;
        }
        .orbit-slide{
          height: inherit!important;
          .container-img-slider{
            height: inherit!important;
            display: table;
            .container-talent-info-slide{
              display: table-cell;
              vertical-align: middle;
            }
            .content-img-slider{
              display: table-cell;
              vertical-align: middle;
              img{

              }
            }
          }
        }
      }
    }
  }

  .container-talent-info-slide{
    h1{
      font-family: Helvetica, serif;
      font-size: 2rem;
      border: 0;
      margin-bottom: 2rem;
      height: 2rem;
      font-weight: 100;
    }
    li{
      margin-bottom: 2vh;
      font-family: Helvetica, serif;
      font-weight: 100;
      &.container-social-media{
        ul{
          display: block;
          text-align: center;
          padding: 0 30px;
          margin: 0;
          li{
            display: inline;
            margin: 0 15px;
          }
        }
      }
    }
    .menu.vertical{
      line-height: 10px;
    }
  }

  .second-measurement-info{
    margin-top: 1rem!important;
    &.menu > li{
      display: inline-block;
      font-family: Helvetica, serif;
      font-weight: 100;
      margin-right: 1rem;
      strong{
        font-family: Helvetica, serif;
        font-weight: 100;
      }
      &.container-social-media{
        ul{
          display: block;
          text-align: center;
          padding: 0 30px;
          margin: 0;
          li{
            display: inline;
            margin: 0 5px;
          }
        }
      }
    }
  }
}

#print-modal{
  font-family: $font-2;
  font-size: 15px;

  p{
    font-size: 15px;
  }
}


.container-social-media{
  position: relative;
  z-index: 20;
}


.menu-talent{
  margin-left: 30px;
  padding-top: 20px;
  li{
    display: inline-block;
    margin-right: 15px;
  }
}

.arrow-icons {
  width: 40px;
}

.minicons{
  width: 23px;
  position: relative;
  top:-1px;
}

.print-icon {
  width: 20px;
  padding-top:20px;
}


.orbit-arrow-videos {
  width:10%!important;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 10%;
  width: 75%;
  height: 60%;
}

.icon-display{
  height: 26px;
}

.menu-talent{
  padding-right: 40px;
  a{
    display: inline-block;
    padding: 10px;
  }
}

